*,
body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
